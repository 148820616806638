.header-container {
    display: flex;
    justify-content: center;
    align-items: flex-end; /* Align the text overlay to the bottom */
    background-image: url('./bannerflagReady.jpg'); /* Adjust the path as needed */
    background-size: cover;
    background-position: center;
    height: 400px; /* Adjust based on your needs */
    text-align: center;
    padding: 20px; /* Padding to ensure text does not touch the edges */
}

.text-overlay {
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent overlay for better readability */
    color: white; /* Adjust text color based on your background */
    padding: 20px; /* Padding inside the text overlay */
    width: 100%; /* Ensures the text overlay spans the width of the container */
    box-sizing: border-box; /* Includes padding in the width calculation */
}

.text-overlay h1 {
    margin: 0; /* Removes default margin */
    font-size: 2em; /* Large, adjust as needed */
}

.text-overlay p {
    margin: 10px 0 0; /* Provides a small gap above the paragraph */
    font-size: 1em; /* Smaller, adjust as needed */
}

.flag-heading {
    color: azure; /* Update this variable or replace it with a specific color code */
    font-family: "Kanit", Sans-serif;
    font-size: 48px; /* Adjust if needed */
    font-weight: 900;
    text-transform: uppercase;
    line-height: 0.889em;
    letter-spacing: 1px;
    text-shadow: 3px 3px 0px rgba(155, 155, 155, 0.3);
    margin: 0 auto; /* Center the heading */
}

/* Create a new class for the subheading with adjusted styles */
.flag-subheading {
    word-wrap: break-word;
    overflow-wrap: break-word;
    color: azure; /* Update this variable or replace it with a specific color code */
    font-family: "Kanit", Sans-serif;
    font-size: 18px; /* Smaller font size */
    font-weight: 700; /* Slightly lighter weight */
    text-transform: uppercase;
    line-height: 1em;
    letter-spacing: 0.8px;
    text-shadow: 2px 2px 0px rgba(155, 155, 155, 0.3);
    margin: 20px auto; /* Adjust spacing as needed */
}

.text-heading-container {
    display: flex;
    flex-direction: column; /* Stack the elements vertically */
    align-items: center; /* Center align the children */
    justify-content: center; /* Center vertically */
    width: 100%;
    max-width: 600px; /* Adjust to the max width you want for the widget and text */
}
